import { Card, IconButton, TextField } from "@mui/material"
import PublishIcon from '@mui/icons-material/Publish';

export const NewQuestion = (
    {
        question,
        handleSubmit,
        handleInputChange,
        responseStates,
        i,
    }:any
) => {
    return (
        <div className='q-container'>
            <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF", }} className='q-card'>
                <div className='q-body'>
                    {i + 1} {question.body}
                </div>
                <div className='q-submit'>
                    <IconButton disabled={false} id={`submit-btn-${question.id.toString()}`} color='primary' size='small' onClick={() => handleSubmit(question, question.id)} className='q-save-btn'><PublishIcon fontSize='large' /></IconButton>
                </div>
                <div className='q-response'>
                    <TextField disabled={false} id={`submit-input-${question.id.toString()}`} onChange={(e) => handleInputChange(e, question.id)} className='q-response-input' size='small' type='text' value={responseStates[question.id] || ''}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") handleSubmit(question, question.id)
                        }}
                    />
                </div>
            </Card>
        </div>
    )
}