import { Card, IconButton, TextField, Tooltip } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

export const MobileAddQuestion = (
    {
        questionToAdd,
        handleAdd,
        handleInputChange,
        responseStates,
        setAddQuestionMode
    }: any
) => {
    return (
        <div className='m-q-container' style={{ display: "flex" }}>
            <Card raised elevation={24} sx={{ width: "90%", height: "5em", bgcolor: "#FCFAFF", }} className='m-q-card'>
                <div className='m-q-body'>
                    Add: {questionToAdd.body}
                </div>
                <div className='m-q-submit'>
                    <IconButton style={{ marginTop: "-0.2em" }} color='primary' size='small' onClick={() => handleAdd(questionToAdd, questionToAdd.id)} className='m-q-save-btn'><AddIcon fontSize='large' /></IconButton>
                </div>
                <div className='m-q-textfield-div'>
                    <TextField sx={{ width: "80%" }} onChange={(e) => handleInputChange(e, questionToAdd.id)} className='m-q-response-input' size='small' type='text' value={responseStates[questionToAdd.id] || ''}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") handleAdd(questionToAdd, questionToAdd.id)
                        }}
                    />
                </div>
            </Card>
            <Tooltip title="Close">
                <IconButton className='m-q-add' onClick={() => setAddQuestionMode(false)}>
                    <CloseIcon sx={{ color: "lightgreen" }} className='m-q-add-btn' fontSize='large' />
                </IconButton>
            </Tooltip>
        </div>
    )
}