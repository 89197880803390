import React, { useState } from 'react';
import { Checkbox, Grid, IconButton, Link, Paper, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Container } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';

export const AccountPage = ({ loggedInUser }: any) => {

    const [settingsMode, setSettingsMode] = useState(false)

    const handleSettings = () => {
        console.log("click",settingsMode)
        setSettingsMode(!settingsMode)
    }

    //if in settings mode

    if (settingsMode) {
        return (
            <Container dark maxWidth="sm">
                <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem', position: 'relative' }}>
                    <IconButton
                        style={{ position: 'absolute', top: '1rem', right: '1rem' }}
                        onClick={handleSettings}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" gutterBottom>
                        Settings
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Email
                            </Typography>
                            <Typography variant="body1">{loggedInUser.email}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Membership
                            </Typography>
                            {loggedInUser.paidUser ?
                                <Typography variant="body1">
                                    Subscribed <Checkbox disableRipple color="success" checked={true} />
                                    <Link href="https://billing.stripe.com/p/login/test_9AQ8yAgTCeYL1nW8ww">
                                        <i style={{fontSize: "15px", float: "right"}}>Cancel Membership</i>
                                    </Link>
                                </Typography>
                                :
                                <Typography variant="body1">
                                    <Link href="membership">
                                        Become a Member
                                    </Link>
                                </Typography>
                            }

                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }

    //if in view mode

    return (
        <Container dark maxWidth="sm">
            <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem', position: 'relative' }}>
                <IconButton
                    style={{ position: 'absolute', top: '1rem', right: '1rem' }}
                    onClick={handleSettings}
                >
                    <SettingsIcon />
                </IconButton>
                <Typography variant="h4" gutterBottom>
                    Account Information
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Email
                        </Typography>
                        <Typography variant="body1">{loggedInUser.email}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Membership
                        </Typography>
                        {loggedInUser.paidUser ?
                            <Typography variant="body1">
                                Subscribed
                                <Checkbox disableRipple color="success" checked={true} />
                            </Typography>
                            :
                            <Typography variant="body1">
                                Free Membership
                                <Checkbox disableRipple color="success" checked={false} />
                            </Typography>
                        }

                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

