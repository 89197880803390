import { fetchStripeCustomersByEmail, fetchStripeSubscriptionsByEmail, fetchStripeLifetimeCharges } from "../../managers/stripeManager"


//Product Ids

//Monthly TEST Product Id:          prod_QOZdYRyEQeyuMa
//Yearly TEST Product Id:           null
//Lifetime TEST Product Id:         prod_QObT4Vwe9zvb7i


export const SubChecker = async (loggedInUser: any) => {

    //setting variables
    const userEmail = loggedInUser.email

    const monthlyProdId = "prod_QOZdYRyEQeyuMa"
    const yearlyProdId = 123


    //finding customer account on stripe
    const customer: any = await fetchStripeCustomersByEmail(userEmail)

    console.log("Customer: ", customer)
    //if customer is found
    if (customer !== null) {
        console.log('customer found...')

        //==================================================that customers subscriptions=================================
        const subscriptions: any = await fetchStripeSubscriptionsByEmail(userEmail);
        console.log("sub: ", subscriptions)

        if (subscriptions?.length >= 1 && subscriptions.length !== null) {
            for (const s of subscriptions) { //iterate through all the users subscriptions
                console.log("product id: ", s.items[0].price.productId)
                if (s.items[0].price.productId === monthlyProdId) { //if subscriptions product id matches the MONTHLY sub product id
                    console.log("Monthly Subscription found!\nreturning true...")
                    if (s.status === "active") return true //check if subscription is active, and if so return true
                }
                else if (s.items[0].price.productId === yearlyProdId) { //if YEARLY
                    console.log("Yearly Subscription found!\nreturning true...")
                    if (s.status === "active") return true //and active, return true
                }
            }
        }

        console.log("No subscription found...\ntrying Lifetime charges...")
    }
    //if a guest customer was used (meaning the user paid with the same card used on a different account)
    console.log("\n\n\nTrying Lifetime charges...")

    //that users charges
    const userLifetimeCharges: any = await fetchStripeLifetimeCharges(userEmail)

    if (userLifetimeCharges.length >= 1) {
        for (const ulc of userLifetimeCharges) {
            console.log("userlifetime charge: ", ulc)
            if (ulc.status === "succeeded") {

                return true;
            }
        }
    }

    return false;


}

// fetchStripeCustomers(userEmail).then((cObj) => { //collects all Stripe Customers (cards) with the logged in user's email
//     console.log('Customers',cObj.data)
//     for (const c of cObj.data) { //for every customer (card) found under that email
//         fetchSubscriptionActivity(c.id).then((sObj) => { //check if there is a subscription
//             console.log('Subscriptions',sObj) //log that subscription, empty or otherwise
//             //check if one of the subscriptions attached to a card is active
//             for (const s of sObj.data) {
//                 console.log('subsubs',s)
//                 if(s.status === 'active'){
//                     //if so, return TRUE
//                     return true;
//                 }
//             }
//         })
//     }
// })

// //if not, return FALSE
// return false;
