import { Button } from "reactstrap"
import "./HomePage.css"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { fetchMonthlyCharges, fetchStripeCustomersByEmail, fetchStripeLifetimeCharges, fetchStripeSubscriptionsByEmail } from "../../../managers/stripeManager"

export const HomePage = ({loggedInUser}:any) => {


    console.log(loggedInUser)
    const navigate = useNavigate();

    const handleGetStarted = () => {
        if(loggedInUser) navigate("/myhome")
            else navigate("/login")
    }

    // const checkStripe = async () => {
    //     const userEmail = loggedInUser.email

    //     const customers = await fetchStripeCustomersByEmail(userEmail)

    //     console.log("Customers: ",customers)

    //     const subscriptions:any = await fetchStripeSubscriptionsByEmail(userEmail);

    //     console.log("Subscriptions: ",subscriptions)

    //     const allLifetimeCharges:any = await fetchStripeLifetimeCharges(userEmail)
    //     const userLifetimeCharges:any = await allLifetimeCharges.filter((lc: any) => lc.billingDetails?.email === userEmail)

    //     console.log("UserLifetimeCharges: ",userLifetimeCharges)

    // }

    return (
        <main className="hp-main">
            <section className="hp-section-header">
                <div className="hp-header-one">
                    Record, Edit, and Manage
                </div>
                <div className="hp-header-two">
                    YOUR DATA
                </div>
                <div className="hp-header-three">
                    <i>Simply, Securly, and Efficiently</i>
                </div>
            </section>
            <section className="hp-section-getstarted">
                <div className="hp-btn-div">
                    <Button onClick={handleGetStarted} className="hp-btn" size="lg" color="primary" variant="contained">Get Started</Button>
                </div>
            </section>
                {/* <Button onClick={checkStripe}>check sub</Button> */}
        </main>
    )
}