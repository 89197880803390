import { Card, IconButton, Tooltip } from "@mui/material"

import AddIcon from '@mui/icons-material/Add';

export const MobileMultipleResponseView = (
    {
        question,
        userQuestion,
        handleAddResponse,
    }: any
) => {

    console.log(userQuestion)

    return (
        <div className='m-q-container' style={{ display: "flex" }}> {/* this one funky */}
            <Card raised elevation={24} sx={{ width: "90%", bgcolor: "#FCFAFF" }} className='m-q-card'>
                <div className='m-q-body'>
                    {question.body}
                </div>
                <div className='m-q-dial'>
                </div>
                <div className='m-q-response'>
                    {userQuestion.response !== undefined ? (userQuestion.response) : ("loading")}
                </div>
            </Card>
            <Tooltip title="Add Response">
                <IconButton className='m-q-add' onClick={() => handleAddResponse(question)}>
                    <AddIcon sx={{ color: "lightgreen" }} className='m-q-add-btn' fontSize='large' />
                </IconButton>
            </Tooltip>
        </div>
    )
}