import { Button, Card, IconButton, Tooltip } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import { deleteUserQuestion } from "../../../../../managers/userQuestionManager";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Modal from '@mui/material/Modal';

export const MultipleResponseView = (
    {
        question,
        userQuestion,
        setEditMode,
        setReload,
        reload,
        handleAddResponse,
        screenWidth
    }:any
) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBtns = async (action: string) => {

        if (action === "Copy") {
            console.log("copying")

            navigator.clipboard.writeText(userQuestion.response).then(() => {
                console.log(userQuestion.response + ' copied to clipboard')
            })
        }
        if (action === "Delete") {
            await deleteUserQuestion(userQuestion.id)
            await setReload(!reload)
        }

        if (action === "Edit") {
            console.log("edio")
            setEditMode(true)
        }

    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'black',
        color: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div className='q-container' style={{ display: "flex" }}>
            <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF", height: "80px" }} className='q-card'>
                <div className='q-body' style={{ fontSize: "15px" }}>
                    {question.body}
                </div>
                <div className='q-response' style={{ paddingBottom: "0.75rem", fontSize: "24px" }}>
                    {userQuestion.response !== undefined ? (userQuestion.response) : ("loading")}
                </div>
            </Card>
            <div className="q-side-btn-container">
                <div className="top-container">
                    <Tooltip title="Delete">
                        <IconButton className="delete-btn" onClick={handleOpen}><DeleteIcon /> </IconButton>
                    </Tooltip>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Card className='q-modal' sx={style}>
                            <div className='q-modal-header'>
                                Are you sure you wan't to delete this response?
                            </div>
                            <div className='q-modal-footer'>
                                <Button className='q-modal-btn q-m-b-cancel' variant='outlined' color='primary'>Cancel</Button>
                                <Button className='q-modal-btn q-m-b-delete' onClick={() => handleBtns("Delete")} variant='contained' color='warning'>DELETE</Button>
                            </div>
                        </Card>
                    </Modal>
                    <IconButton className="edit-btn" onClick={() => handleBtns("Edit")}> <ModeEditIcon /> </IconButton>
                </div>
                <div className="bottom-container">
                    <Tooltip title="Copy">
                        <IconButton className="copy-btn" onClick={() => handleBtns("Copy")}> <FileCopyIcon /> </IconButton>
                    </Tooltip>
                    <Tooltip title="Add Response">
                        <IconButton  onClick={() => handleAddResponse(question, userQuestion)} className="add-btn"> <AddIcon sx={{ color: "lightgreen" }}/> </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}