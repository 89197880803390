import { Card, IconButton, TextField } from "@mui/material"
import PublishIcon from '@mui/icons-material/Publish';

export const MobileNewQuestion = (
    {
        question,
        handleSubmit,
        handleInputChange,
        responseStates,
        i,
    }: any
) => {
    return (
        <div className='m-q-container'>
            <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF", height: "5em" }} className='m-q-card'>
                <div className='m-q-body'>
                    {i + 1} {question.body}
                </div>
                <div className='m-q-submit'>
                    <IconButton disabled={false} id={`submit-btn-${question.id.toString()}`} color='primary' size='small' onClick={() => handleSubmit(question, question.id)} className='m-q-save-btn'><PublishIcon fontSize='large' /></IconButton>
                </div>
                <div className='m-q-textfield-div'>
                    <TextField sx={{ width: "80%" }} disabled={false} id={`submit-input-${question.id.toString()}`} onChange={(e) => handleInputChange(e, question.id)} className='m-q-response-input' size='small' type='text' value={responseStates[question.id] || ''}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") handleSubmit(question, question.id)
                        }}
                    />
                </div>
            </Card>
        </div>
    )
}