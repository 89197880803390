import { Card } from "@mui/material"

export const MobileSingleResponseView = ({ question }: any) => {

    const userQuestion = question.userQuestions[0]
    console.log("5235",question)

    return (
        <div className='m-q-container'>
            <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF" }} className='m-q-card'>
                <div className='m-q-body'>
                    {question.body}
                </div>
                <div className='m-q-dial'>
                </div>
                <div className='m-q-response'>
                    {userQuestion.response !== undefined ? (userQuestion.response) : ("loading")}
                </div>
            </Card>

        </div>
    )
}





